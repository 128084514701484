import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Sidebar from "../components/Sidebar";
import { Select } from "../components/Core";
import axios from "axios";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import { useFilterSearch } from '../context/FilterSearchContext';
import GlobalContext from "../context/GlobalContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';

const defaultCountries = [
    { value: "sp", label: "Singapore" },
    { value: "bd", label: "Bangladesh" },
    { value: "usa", label: "United States of America" },
    { value: "uae", label: "United Arab Emirates" },
    { value: "pk", label: "Pakistan" },
];

const SavedJobListingGrid = () => {

    const [showMore, setShowMore] = useState([]);

    const toggleShowMore = (id) => {
        if (showMore.includes(id)) {
            setShowMore(showMore.filter(i => i !== id));
        } else {
            setShowMore([...showMore, id]);
        }
    };

    const imageBaseUrl = `${network.serverip}/images/`;

    const [search, setSearch] = useState('');
    const [count, setCount] = useState(10);
    const [totalJobCount, setTotalJobCount] = useState(0);
    const [resultsFor, setResultsFor] = useState('');
    const [jobs, setJobs] = useState([]);
    const isMountedRef = useRef(false);
    const gContext = useContext(GlobalContext);
    const { user } = useContext(GlobalContext);
    const [showMenuJobId, setShowMenuJobId] = useState(null);

    const [userId, setUserId] = useState('');

    const toggleMenuButton = (id) => {
        setShowMenuJobId(showMenuJobId === id ? null : id)
    }

    const handleSearch = async (event) => {
        event.preventDefault();
        setCount(10);
        setShowMore([]);
        await fetchSavedJobs(search, 10);
    };

    const handleLoadMore = async () => {
        try {
            const newCount = count + 10;
            setCount(newCount);
            await fetchSavedJobs(search, newCount);
        } catch (error) {
            console.error('Error loading more jobs:', error);
        }
    };

    const fetchSavedJobs = async (search, count) => {
        try {
            const response = await axiosConfig.post('/saved-joblist-forweb', { search, count, userId });
            if (response.data.success && isMountedRef.current) {
                setJobs(response.data.data);
                setTotalJobCount(response.data.total);
                setResultsFor(search);
            } else if (!response.data.success) {
                if (isMountedRef.current) {
                    setJobs([]);
                    setTotalJobCount(0);
                    setResultsFor(search);
                }
                console.error('Failed to fetch jobs');
            }
        } catch (error) {
            if (isMountedRef.current) {
                setJobs([]);
                setTotalJobCount(0);
                setResultsFor(search);
            }
            console.error('Error fetching jobs:', error);
        }
    };

    const handleJobSaveOrUnsave = async (jobId) => {
        try {

            if (!userId) {
                gContext.toggleSignInModal();

                return
            }

            if (userId && jobId) {
                const response = await axiosConfig.post(`/saveOrUnsaveJob-web`, { userId, jobId });

                if (response.data.success && isMountedRef.current) {

                    const { action, data } = response.data;

                    if (action === 'unsaved') {

                        setJobs((prevState) =>
                            prevState.filter((job) => job.id !== data.job_id)
                        );

                        setTotalJobCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));

                        console.log('Job unsaved');
                    } else if (action === 'saved') {
                        // Optionally, you might want to add the job back into the list when saved, 
                        // depending on how you're managing the job state
                        console.log('Job saved');
                    }

                    console.log('Job saved / unsaved');
                } else {
                    if (isMountedRef.current) {
                        console.log('Failed to save / unsave job');
                    }
                    console.error('Failed to save / unsave job:', response.data.message);
                }
            } else {
                console.error('userId or jobId is missing');
            }
        } catch (error) {
            console.error('Failed to save/ unsave job:', error);
        }
    };

    useEffect(() => {
        if (user && user.id) {
            setUserId(user.id)
        }
    }, [user]);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                await fetchSavedJobs(search, count);
            };

            fetchData();
        }
    }, [userId]);



    const styles = SavedJobListingGridStyling();

    // // Helper function to split array into chunks
    // const chunkArray = (array, chunkSize) => {
    //     const results = [];
    //     for (let i = 0; i < array.length; i += chunkSize) {
    //         results.push(array.slice(i, i + chunkSize));
    //     }
    //     return results;
    // };

    // const jobPairs = chunkArray(jobs, 2);

    return (
        <>
            <PageWrapper>
                <div className="bg-default-1 pt-32 pt-lg-28 pb-13 pb-lg-25">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-12 col-md-4 col-xs-8">
                                <Sidebar />
                            </div> */}

                            {/* className="col-12 col-xl-8 col-lg-8" */}
                            <div className="col-12">
                                {/* <!-- form --> */}
                                <form className="search-form" onSubmit={handleSearch}>
                                    <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                                        <div className="filter-inputs">
                                            <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                                                <input
                                                    className="form-control focus-reset pl-13"
                                                    type="text"
                                                    id="search"
                                                    value={search}
                                                    onChange={(event) => setSearch(event.target.value)}
                                                    placeholder="Search"
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                            {/* <!-- .select-city starts --> */}
                                            {/* <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                                                <Select
                                                    options={defaultCountries}
                                                    className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                                                    border={false}
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                                                </span>
                                            </div> */}
                                            {/* <!-- ./select-city ends --> */}
                                        </div>
                                        <div className="button-block">
                                            <button
                                                className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase"
                                                type="submit"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="pt-12 ml-lg-0 ml-md-15">
                                    <div className="d-flex align-items-center justify-content-between mb-6">
                                        {resultsFor ? (
                                            <h5 className="font-size-4 font-weight-normal text-gray">
                                                <span className="heading-default-color">{totalJobCount}</span>
                                                {totalJobCount === 1
                                                    ? ` result for `
                                                    : ` results for `}
                                                <span className="heading-default-color">{resultsFor}</span>
                                            </h5>
                                        ) : (
                                            <h5 className="font-size-4 font-weight-normal text-gray">
                                                <span className="heading-default-color">{totalJobCount}</span>
                                                {" "} {"Job(s) found"} {" "}
                                            </h5>
                                        )
                                        }
                                        <div className="d-flex align-items-center result-view-type">
                                            <Link
                                                to="/saved-joblisting"
                                                className="heading-default-color pl-5 font-size-6 hover-text-hitgray"
                                            >
                                                <i className="fa fa-list-ul"></i>
                                            </Link>
                                            <Link
                                                to="/saved-joblisting-grid"
                                                className="heading-default-color pl-5 font-size-6 hover-text-hitgray active"
                                            >
                                                <i className="fa fa-th-large"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="pt-6">
                                        <div className="row justify-content-center">
                                            {jobs.map((job, index) => (
                                                <div className="col-12 col-lg-6  col-xl-4" key={index}>
                                                    <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                                                        <div className="d-block mb-7">
                                                            <div style={styles.imageContainer}>
                                                                <button
                                                                    onClick={() => gContext.toggleImageViewModal(job?.image)}
                                                                    style={styles.imageViewButton}
                                                                >
                                                                    <img
                                                                        src={job && job.image ? imageBaseUrl + job.image : ""}
                                                                        alt=""
                                                                        style={styles.jobImage}
                                                                    />
                                                                </button>
                                                                {showMenuJobId === job?.id &&
                                                                    <>
                                                                        {job?.isReported === 1 ?
                                                                            (
                                                                                <button
                                                                                    style={styles.reportButton}
                                                                                    className="font-size-4 text-gray"
                                                                                >
                                                                                    <i className="fas fa-info-circle text-gray"></i>
                                                                                    <span className="text-gray ml-5">Already Reported</span>
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    onClick={() => { gContext.toggleReportJobModal(job?.id) }}
                                                                                    style={styles.reportButton}
                                                                                    className="font-size-4 text-danger"
                                                                                >
                                                                                    <i className="fas fa-info-circle text-danger"></i>
                                                                                    <span className="text-danger ml-5">Report</span>
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                                <button
                                                                    onClick={() => { toggleMenuButton(job?.id) }}
                                                                    style={styles.openMenuIcon}
                                                                >
                                                                    <i className="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <h3 className="font-size-6 heading-default-color">
                                                                <Link to={`/job-details/?id=${job.id}`}
                                                                    className="font-size-6 heading-default-color d-block mb-0">
                                                                    {job && job.title ? job.title : ''}
                                                                </Link>
                                                            </h3>
                                                            <div className="mt-n4">
                                                                <p className="font-size-small font-weight-bold text-black-2 mb-4">
                                                                    <span className="mr-2">
                                                                        <FontAwesomeIcon icon={faHospitalAlt} />
                                                                    </span>
                                                                    {job && job.institution && job.institution.title ? job.institution.title : ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                                                                    <li style={styles.jobTypeBox}>
                                                                        <p className="text-black-2 font-size-3  mb-0" style={{ margin: 0, padding: 0 }}>
                                                                            <i className="fa fa-briefcase mr-2"></i>
                                                                            {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                                                        </p>
                                                                    </li>
                                                                    <li style={styles.locationBox}>
                                                                        <p className="text-black-2 font-size-3  mb-0" style={{ margin: 0, padding: 0 }}>
                                                                            <i className="icon icon-pin-3 mr-2"></i>
                                                                            {job && job.location && job.location.title ? job.location.title : ''}
                                                                        </p>
                                                                    </li>
                                                                    <li style={styles.salaryBox}>
                                                                        <p className="text-black-2 font-size-3  mb-0" style={{ margin: 0, padding: 0 }}>
                                                                            <span className="mr-2">₹</span>
                                                                            {job && job.salary ? `${job.salary}/month` : ''}
                                                                        </p>
                                                                    </li>
                                                                </ul> */}
                                                        <ul className="list-unstyled mb-1 card-tag-list" style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                                                            <li style={styles.jobTypeBox}>
                                                                <div
                                                                    className="bg-regent-opacity-15 text-orange font-size-3 rounded-3"
                                                                >
                                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                                    {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                                                </div>
                                                            </li>
                                                            <li style={styles.locationBox}>
                                                                <div
                                                                    className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                                                                >
                                                                    <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                                                    {job && job.location && job.location.title ? job.location.title : ''}
                                                                    {job && job.state && job.state.title ? `, ${job.state.title}` : ''}
                                                                </div>
                                                            </li>
                                                            {job?.created_at && (
                                                                <li style={styles.createdDateBox}>
                                                                    <div
                                                                        className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                                                                    >
                                                                        {new Date(job.created_at).toLocaleDateString('default', {
                                                                            day: '2-digit',
                                                                            month: 'short',
                                                                            year: 'numeric'
                                                                        }).replace(/ /g, '-')}
                                                                    </div>
                                                                </li>
                                                            )}
                                                            {/* {job?.salary && job.salary > 0 && (
                                                                            <li style={styles.salaryBox}>
                                                                                <div
                                                                                    className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                                                                                >
                                                                                    <span className="mr-2">₹</span>
                                                                                    {job && job.salary ? `${job.salary}/month` : ''}
                                                                                </div>
                                                                            </li>
                                                                        )} */}
                                                        </ul>
                                                        <div className="mb-2" style={{ textAlign: 'justify' }}>
                                                            {job && job.description ? (
                                                                <p className="font-size-4 text-black-2">
                                                                    {((showMore.includes(job.id)) || job.description.length <= 150)
                                                                        ? `${job.description} `
                                                                        : `${job.description.slice(0, 150)}... `}
                                                                    {job.description.length > 150 && (
                                                                        // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                                                                        //     {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                                                                        // </span>
                                                                        <Link to={`/job-details/?id=${job.id}`} style={styles.showMoreButton}>
                                                                            {'Show more'}
                                                                        </Link>
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                null
                                                            )}
                                                        </div>
                                                        <div className="w-100 mb-2">
                                                            {job?.posted_by?.name && (
                                                                <p className="font-size-3 text-gray mb-0">
                                                                    {`Posted by: ${job.posted_by.name}`}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="card-btn-group d-flex justify-content-between">
                                                            {job?.isApplied === 1 ? (
                                                                <button className="btn btn-gray text-uppercase btn-medium rounded-3" disabled>
                                                                    Applied
                                                                </button>
                                                            ) : (
                                                                <Link
                                                                    to={`/job-apply/?id=${job?.id}`}
                                                                    className="btn btn-green text-uppercase btn-medium rounded-3"
                                                                >
                                                                    Apply
                                                                </Link>
                                                            )}
                                                            {/* <Link to="/#" className="btn btn-outline-mercury text-black-2 text-uppercase btn-medium rounded-3">
                                                                        <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i> Save it
                                                                    </Link> */}
                                                            {/* {job && job.isSaved && job.isSaved === 1 ? (
                                                                        <div onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                            className="btn btn-outline-mercury text-uppercase h-px-48 rounded-3 mb-5 px-5">
                                                                            <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-success"></i>{" "}
                                                                            <span className="text-success">Saved</span>
                                                                        </div>
                                                                    ) : (
                                                                        <div onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                            className="btn btn-outline-mercury text-black-2 text-uppercase h-px-48 rounded-3 mb-5 px-5">
                                                                            <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                                                            Save job
                                                                        </div>
                                                                    )} */}

                                                            <button onClick={() => handleJobSaveOrUnsave(job?.id)}
                                                                className="btn btn-outline-mercury h-px-48 rounded-3 mb-5 px-5">
                                                                {/* <i className="fas fa-bookmark font-weight-bold mr-4 font-size-4 text-danger"></i>{" "} */}
                                                                <span className="text-danger">REMOVE</span>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="text-center pt-5 pt-lg-13">
                                        {/* <Link
                                            to="/#"
                                            className="text-green font-weight-bold text-uppercase font-size-3 d-flex align-items-center justify-content-center"
                                        >
                                            Load More{" "}
                                            <i className="fas fa-sort-down ml-3 mt-n2 font-size-4"></i>
                                        </Link> */}
                                        {jobs.length > 0 && totalJobCount > jobs.length ? (
                                            <button onClick={handleLoadMore}
                                                className="btn btn-green text-uppercase btn-medium rounded-3"
                                            >
                                                <span>
                                                    Load More <i className="fas fa-arrow-down ml-2"></i>
                                                </span>
                                            </button>
                                        ) : null}

                                        {jobs.length === 0 && (
                                            <span className="heading-default-color font-weight-bold font-size-3">
                                                No jobs found
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {/* <!-- form end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default SavedJobListingGrid;

function SavedJobListingGridStyling() {
    const styles = {
        showMoreButton: {
            display: 'inline-block',
            background: 'none',
            border: 'none',
            color: 'blue',
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        imageContainer: {
            width: '100%',
            aspectRatio: '4 / 3',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
        },
        jobImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        // loadMoreButton: {
        //     border: 'none',
        //     background: 'none',
        // },
        jobTypeBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        locationBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        salaryBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
        },
        createdDateBox: {
            backgroundColor: '#f0f0f0',
            padding: '5px',
            borderRadius: '5px',
            margin: '5px',
            display: 'flex',
            justifyContent: 'center',
        },
        imageViewButton: {
            outline: 'none',
            border: 'none',
            background: 'none',
            width: '100%',
            height: '100%',
            padding: '0',
        },
        openMenuIcon: {
            position: 'absolute',
            right: '2.9rem',
            top: '2rem',
            background: 'white',
            border: 'none',
            borderRadius: '3px',
            color: 'inherit',
            cursor: 'pointer',
            outline: 'none',
        },

        reportButton: {
            position: 'absolute',
            right: '4.2rem',
            top: '1.9rem',
            width: 'auto',
            height: 'auto',
            background: 'white',
            border: '1px solid',
            borderRadius: '5px',
            paddingHorizontal: '6px',
            paddingVertical: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
        }
    };

    return styles;
}
